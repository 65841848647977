import React, { useEffect, useState } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading  } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";

export function AccuracyStep() {
  const {
    state: { accuracyScore },
    dispatch,
  } = useHealthCheckAppState();

  const [value, setValue] = useState();

  const isValid = accuracyScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-accuracy' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [accuracyScore])

  return (
    <>
      <Box
        style={{
          marginTop: '20px',
          width: '480px'
        }}>
        <Heading level="3">We have 6 quick questions. Let's start with an easy one:</Heading>
        <Paragraph margin={{
          bottom: '0',
          top: 'small'
        }}>How many times have you <b>found minor errors</b> in your books in the last year?</Paragraph>
      </Box>
      <Box 
        direction="row" 
        justify="between" 
        align="center"
        margin={{ top: "medium" }}
        pad={{ bottom: '40px' }}
        style={{
          width: 480 + 'px'
        }}
        >
      <Form>
        <RadioButtonGroup
          name="accuracyScore"
          options={[
            { label: 'One to two times', value: '1' },
            { label: 'Three to five times', value: '3' },
            { label: 'Six to ten times', value: '5' },
            { label: 'Too many to count', value: '5-1' },
          ]}
          value={accuracyScore}
          onChange={(event) => {
            const value = event.target.value;
            dispatch({ type: "ACCURACY_SCORE_CHANGE", payload: value })
          }}
        />
      </Form>
      </Box>
    </>
  );
}

export default AccuracyStep;
