import React from 'react';
import { Image } from 'grommet';
import styled from 'styled-components';

const ImgContainer = styled.div`
  max-width: 520px;
  width: 100%;
`;

const scoreImg = (breakdownScore) => {
  let imgSrc;
  if ( breakdownScore <= 2.9 ) {
    imgSrc = 'https://images.ctfassets.net/l07wyut35yzl/4BGmzt8ElD3rE63LU3rgzx/60195598dca9600a9691fe32dae56495/img-on-the-right-path2.svg';
  } else if ( breakdownScore >= 3 && breakdownScore <= 4.9 ) {
    imgSrc = 'https://images.ctfassets.net/l07wyut35yzl/6WlT6JRKvoNGfphXK1hZSQ/63759c73762a26ceb263fae4e2f7c263/img-opportunity-for-change2.svg';
  } else {
    imgSrc = 'https://images.ctfassets.net/l07wyut35yzl/4P0ocOZq2oV7z0YC9daz9f/13ec13468f9dea5123364285c86381a1/img-you-deserve-better2.svg';
  };
  return imgSrc
}

export function BreakdownScoreImage({ breakdownScore }) {
  return (
    <ImgContainer>
      <Image
        src={scoreImg(breakdownScore)}
        style={{
          width: '100%'
        }}
      />
    </ImgContainer>
  );
}
