import React, { useEffect } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading  } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";

export function TimelinessReportStep() {
  const {
    state: { timelinessReportScore },
    dispatch,
  } = useHealthCheckAppState();

  const isValid = timelinessReportScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-timelinessreport' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [timelinessReportScore])

  return (
    <>
      <Box
        style={{
          marginTop: 20 + 'px'
        }}>
        <Heading level="3">Meanwhile...</Heading>
        <Paragraph margin={{
          bottom: '0',
          top: 'small'
        }}>How many times have your monthly financial statements arrived on your desk <b>later than you expected</b> in the last year?</Paragraph>
      </Box>
      <Box 
        direction="row" 
        justify="between" 
        align="center"
        margin={{ top: "medium" }}
        pad={{ bottom: '40px' }}
        style={{
          width: 480 + 'px'
        }}
        >
      <Form>
        <RadioButtonGroup
          name="timelinessReportScore"
          options={[
            { label: 'Once', value: '1' },
            { label: 'Twice', value: '3' },
            { label: 'Three or more times', value: '5' },
            { label: 'So often I have stopped calling them monthly', value: '5-1' },
          ]}
          value={timelinessReportScore}
          onChange={(event) => {
            dispatch({ type: "TIMELINESS_REPORT_SCORE_CHANGE", payload: event.target.value })
          }}
        />
        </Form>
      </Box>
    </>
  );
}

export default TimelinessReportStep;
