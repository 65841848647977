import React, { useEffect} from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading  } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";

export function TimelinessBookClosingStep() {
  const {
    state: { timelinessBookClosingScore },
    dispatch,
  } = useHealthCheckAppState();

  const isValid = timelinessBookClosingScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-timelinessbookclosing' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [timelinessBookClosingScore])

  return (
    <>
      <Box
        style={{
          marginTop: 20 + 'px'
        }}>
        <Heading level="3">Awesome. We're almost there...</Heading>
        <Paragraph margin={{
          bottom: '0',
          top: 'small'
        }}>The <b>previous</b> month's books are closed on <b>which date</b> of the month?</Paragraph>
      </Box>
      <Box 
        direction="row" 
        justify="between" 
        align="center"
        margin={{ top: "medium" }}
        pad={{ bottom: '40px' }}
        style={{
          width: 480 + 'px'
        }}
        >
      <Form>
        <RadioButtonGroup
          name="timelinessBookClosingScore"
          options={[
            { label: 'First to fifth of the month', value: '3' },
            { label: '6th to 10th', value: '3-1' },
            { label: '11th to 20th', value: '5' },
            { label: 'After the 20th', value: '5-1' },
            { label: 'There is no fixed date', value: '5-2' },
            { label: 'We don\'t do monthly closing', value: '5-3' },
          ]}
          value={timelinessBookClosingScore}
          onChange={(event) => {
            dispatch({ type: "TIMELINESS_BOOKCLOSING_SCORE_CHANGE", payload: event.target.value })
          }}
        />
        </Form>
      </Box>
    </>
  );
}

export default TimelinessBookClosingStep;
