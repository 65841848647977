import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Box, Select, Paragraph, Text, Heading } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";

const ScoreRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 560px;
  margin: 24px 0;
`;

const ScoreRowLabel = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`;

export function RelevancyStep() {
  const {
    state: { relevancyScore },
    dispatch,
  } = useHealthCheckAppState();

  const isValid = relevancyScore.PL && relevancyScore.PL.value !== '' 
    && relevancyScore.balanceSheet && relevancyScore.balanceSheet.value !== ''
    && relevancyScore.APReport && relevancyScore.APReport.value !== ''
    && relevancyScore.ARAging && relevancyScore.ARAging.value !== ''
    && relevancyScore.trendData && relevancyScore.trendData.value !== '';

    console.log('>>>>', relevancyScore)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-relevancy' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [relevancyScore])

  const selectOptions = [
    {
      label: 'Weekly',
      value: 1,
    },
    {
      label: 'Monthly',
      value: 3,
    },
    {
      label: 'Sometimes',
      value: 5,
    },
    {
      label: 'Never',
      value: 5,
    },
  ];

  const selectOptionsAlt = [
    {
      label: 'Monthly',
      value: 1,
    },
    {
      label: 'Sometimes',
      value: 5,
    },
    {
      label: 'Never',
      value: 5,
    },
  ];

  return (
    <>
      <Box
        style={{
          marginTop: 20 + 'px'
        }}>
        <Heading level="3">About those reports...</Heading>
        <Paragraph margin={{
          bottom: '20px',
          top: 'small'
        }}><b>When are the following delivered to you?</b></Paragraph>
      </Box>
      <Form>
        <ScoreRow>
          <Box>
            <ScoreRowLabel>P&L</ScoreRowLabel>
          </Box>
          <Select
            options={selectOptions}
            name="selectPL"
            placeholder="Please select"
            value={relevancyScore.PL}
            labelKey="label"
            onChange={({ value }) => {
              dispatch({ type: "RELEVANCY_SCORE_CHANGE", payload: { ...relevancyScore, PL: value } })
            }
            }
          />
        </ScoreRow>
        <ScoreRow>
          <Box>
            <ScoreRowLabel>Balance Sheet</ScoreRowLabel>
          </Box>
          <Select
            options={selectOptionsAlt}
            name="selectBalanceSheet"
            placeholder="Please select"
            value={relevancyScore.balanceSheet}
            labelKey="label"
            valueKey={{ key: 'value', reduce: false}}
            onChange={({ value }) => {
              dispatch({ type: "RELEVANCY_SCORE_CHANGE", payload: { ...relevancyScore, balanceSheet: value } })
            }
            }
          />
        </ScoreRow>
        <ScoreRow>
          <Box>
            <ScoreRowLabel>A/P Report</ScoreRowLabel>
          </Box>
          <Select
            options={selectOptions}
            labelKey="label"
            valueKey={{ key: 'value', reduce: false}}
            name="selectAPReport"
            placeholder="Please select"
            value={relevancyScore.APReport}
            onChange={({ value }) => {
              dispatch({ type: "RELEVANCY_SCORE_CHANGE", payload: { ...relevancyScore, APReport: value } })
            }
            }
          />
        </ScoreRow>
        <ScoreRow>
          <Box>
            <ScoreRowLabel>A/R Aging</ScoreRowLabel>
          </Box>
          <Select
            options={selectOptions}
            labelKey="label"
            valueKey={{ key: 'value', reduce: false}}
            name="selectARAging"
            placeholder="Please select"
            value={relevancyScore.ARAging}
            onChange={({ value }) => {
              dispatch({ type: "RELEVANCY_SCORE_CHANGE", payload: { ...relevancyScore, ARAging: value } })
            }
            }
          />
        </ScoreRow>
        <ScoreRow>
          <Box>
            <ScoreRowLabel>Complex Trend Data</ScoreRowLabel>
          </Box>
          <Select
            options={selectOptions}
            labelKey="label"
            valueKey={{ key: 'value', reduce: false}}
            name="selectTrendData"
            placeholder="Please select"
            value={relevancyScore.trendData}
            onChange={({ value }) => {
              dispatch({ type: "RELEVANCY_SCORE_CHANGE", payload: { ...relevancyScore, trendData: value } })
            }
            }
          />
        </ScoreRow>
      </Form>
    </>
  );
}

export default RelevancyStep;
