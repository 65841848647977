import React, { useState } from "react";
import styled from "styled-components";
import { Button, Form, Box, RadioButtonGroup, Paragraph, Heading, ThemeContext   } from 'grommet';
import { AccuracyStep, TimelinessReportStep, AutomationStep, TimelinessBookClosingStep, RelevancyStep, ValueCreationStep, ResultOverallStep, ResultBreakdownStep } from "./steps";
import { useHealthCheckAppState } from "./HealthCheckAppContext";
import { Checkmark, FormNextLink} from 'grommet-icons';
import { FormModalContext } from '@pwc-new-ventures/ui';

function useFormProgress() {
    const [currentStep, setCurrentStep] = useState(0);

    function goForward() {
        setCurrentStep(currentStep + 1);
    }

    function goBack() {
        setCurrentStep(currentStep - 1);
    }
    return [currentStep, goForward, goBack];
}

const BackButton = styled(Button)`
  background: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #D04A02;
  width: 120px;
  padding:  8px 16px;
  border-radius: 6px;
  margin-right: 20px;
`;

const NextButton = styled(Button)`
  background: #EA8C00;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #FFFFFF;
  padding:  8px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function HealthCheckApp() {
    const { dispatch, state } = useHealthCheckAppState();
    const [currentStep, goForward, goBack] = useFormProgress();

    const { accuracyScore, timelinessReportScore, timelinessBookClosingScore, automationScore, relevancyScore, valueCreationScore } = state;
    const accuracyTotalScore = /5-.*/.test(accuracyScore) ? 5 : parseInt(accuracyScore, 10);
    const timelinessTotalScore = (
        /5-.*/.test(timelinessReportScore) ? 5 : parseInt(timelinessReportScore, 10) 
        + /5-.*/.test(timelinessBookClosingScore) ? 5 : /3-.*/.test(timelinessBookClosingScore) ? 3 : parseInt(timelinessBookClosingScore, 10)
        ) / 2;
    const automationTotalScore = /5-.*/.test(automationScore) ? 5 : parseInt(automationScore, 10);
    const relevancyTotalScore = (
        parseInt(relevancyScore.PL && relevancyScore.PL.value, 10)
         + parseInt(relevancyScore.balanceSheet && relevancyScore.balanceSheet.value, 10)
          + parseInt(relevancyScore.ARAging && relevancyScore.ARAging.value, 10) 
          + parseInt(relevancyScore.APReport &&relevancyScore.APReport.value, 10)) / 4;
          //   + parseInt(relevancyScore.trendData && relevancyScore.trendData.value , 10)
    const valueCreationTotalScore = /5-.*/.test(valueCreationScore) ? 5 : parseInt(valueCreationScore, 10);

    const overallCalc =  (accuracyTotalScore + timelinessTotalScore + automationTotalScore + relevancyTotalScore + valueCreationTotalScore);

    const breakdownCalc = {
        accuracyTotalScore,
        timelinessTotalScore,
        automationTotalScore,
        relevancyTotalScore,
        valueCreationTotalScore
    };

    const controlFunctions = {
        goForward,
        goBack
    }

    const steps = [
        <AccuracyStep {...controlFunctions} />,
        <TimelinessReportStep {...controlFunctions} />,
        <AutomationStep {...controlFunctions} />,
        <TimelinessBookClosingStep {...controlFunctions} />,
        <RelevancyStep {...controlFunctions} />,
        <ValueCreationStep {...controlFunctions} />,
        <ResultBreakdownStep breakdownCalc={breakdownCalc} overallCalc={overallCalc} {...controlFunctions} />
    ];
    const isFirst = currentStep === 0;
    const isLast = currentStep === steps.length - 1;
    const isValueCreationStep = currentStep === steps.length - 2;

    function handleSubmit() {
        dispatch({ type: "SUBMIT" });

        // Simulated network request :)
        setTimeout(() => {
            dispatch({ type: "SUBMISSION_RECEIVED" });
        }, 1500);
    }
    const SuccessFormSubmit = ({ handleModal, handleNextStep }) => {

        const handleClose = () => {
          handleModal(null);
          handleNextStep();
        }
      
        return (
          <>
          <Box align="center" pad="large" flex={false}>
            <ThemeContext.Extend
              value={{
                heading: { level: { '2': { medium: { maxWidth: '600px' } } } },
                paragraph: { medium: { maxWidth: '600px' } },
              }}
            >
              <Checkmark size="large" />
              <Heading level="2" textAlign="center">Thank you.</Heading>
              <Paragraph textAlign="center" margin={{ bottom: 'large' }}>
                Click the button below to reveal your accounting health breakdown.
              </Paragraph>
              <NextButton onClick={handleClose}>Show me the results <FormNextLink size="medium" /></NextButton>
            </ThemeContext.Extend>
          </Box>
          </>
        )
    }
      
    return (
        <Box>
            {steps[currentStep]}
            { (isValueCreationStep) ?
            (<>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '30px',
                    }}>
                    <BackButton onClick={() => goBack()}>Back</BackButton>
                    <FormModalContext.Consumer>
                        {({ setShowModal }) => (
                        <NextButton onClick={() => { setShowModal({ slug: 'form-accounting-healthcheck', hiddenData: { total_health_score: overallCalc, 
                            accuracy_score: breakdownCalc.accuracyTotalScore, 
                            timeliness_score: breakdownCalc.timelinessTotalScore,
                            automation_score: breakdownCalc.automationTotalScore, 
                            relevancy_score: breakdownCalc.relevancyTotalScore, 
                            valuecreation_score: breakdownCalc.valueCreationTotalScore,
                        }, customSuccess: () => <SuccessFormSubmit handleModal={setShowModal} handleNextStep={goForward} /> } ) }}>Get my score <FormNextLink size="medium" /></NextButton>
                        )}
                    </FormModalContext.Consumer>
                </Box>
                <Paragraph
                style={{
                    fontSize: '13px',
                    lineHeight: '18px',
                    marginTop: '60px'
                }}>This estimate is for general demonstration purposes only. For simplicity, this accounting health result makes a series of assumptions and this estimate should not be relied upon for any business decisions.  It is not a substitute for legal, accounting, tax, financial, or other advice or services that would otherwise be provided by a certified professional.</Paragraph>
            </>) : isLast ? null :
                (<>
                    <Box
                        direction="row"
                        style={{
                            marginTop: '20px'
                        }}
                    >
                        {!isFirst &&
                            <BackButton
                                label="Back"
                                onClick={() => goBack()} />}
                        <NextButton
                            label="Next"
                            type="submit"
                            disabled={!state.shouldAllowNext}
                            onClick={e => {
                                e.preventDefault();

                                if (isLast) {
                                    handleSubmit();
                                } else {
                                    goForward();
                                }
                            }}
                        />
                    </Box>
                </>)
            }
        </Box>
    );
}

export default HealthCheckApp;
