import React, { useEffect } from "react";
import { Form, Box, RadioButtonGroup, Paragraph, Heading  } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";

export function AutomationStep() {
  const {
    state: { automationScore },
    dispatch,
  } = useHealthCheckAppState();

  const isValid = automationScore !== '';

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-automation' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [automationScore])

  return (
    <>
      <Box
        style={{
          marginTop: 20 + 'px'
        }}>
        <Heading level="3">You're doing great. Next...</Heading>
        <Paragraph margin={{
          bottom: '0',
          top: 'small'
        }}>How many <b>paper checks</b> does your team write each month?</Paragraph>
      </Box>
      <Box 
        direction="row" 
        justify="between" 
        align="center"
        margin={{ top: "medium" }}
        pad={{ bottom: '40px' }}
        style={{
          width: 480 + 'px'
        }}
        >
      <Form>
        <RadioButtonGroup
          name="automationScore"
          options={[
            { label: '1 - 9', value: '3' },
            { label: '10 - 19', value: '5' },
            { label: '20 - 50', value: '5-1' },
            { label: 'More than 50', value: '5-2' },
          ]}
          value={automationScore}
          onChange={(event) => {
            dispatch({ type: "AUTOMATION_SCORE_CHANGE", payload: event.target.value  })
          }}
        />
        </Form>
      </Box>
    </>
  );
}

export default AutomationStep;
