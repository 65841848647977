import React from 'react';
import {Paragraph } from 'grommet';
import styled from 'styled-components';

const ExplainContainer = styled.div`
  max-width: 480px;
  width: 100%;
`;

const explainResult = (totalScore) => {
  let result;
  if ( totalScore <= 7.4 ) {
    result = 'Great job!';
  } else if ( totalScore >= 7.5 && totalScore <= 17 ) {
    result = 'You can expect more!';
  } else {
    result = 'You are worth it!';
  };
  return result
}

const explainParagraph = (totalScore) => {
  let explainText;
  if ( totalScore <= 7.4 ) {
    explainText = 'Sounds like you have already started to focus on using your books to make better decisions.';
  } else if ( totalScore >= 7.5 && totalScore <= 17 ) {
    explainText = 'Your bookkeeping has room for improvement to help you make better business decisions.';
  } else {
    explainText = 'Your accounting and bookkeeping can be much easier, and more reliable.';
  };
  return explainText
}

export function ScoreExplain({ totalScore }) {
  return (
    <ExplainContainer>
      <Paragraph
        style={{
          padding: '30px 0 10px'
        }}
      >
        <b>{explainResult(totalScore)}</b> {explainParagraph(totalScore)}
      </Paragraph>
    </ExplainContainer>
  );
}
