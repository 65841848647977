import React  from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { Box, Image, Paragraph, ResponsiveContext, Heading } from 'grommet';
// import { linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../components/Healthcheck/layouts';
import HealthCheckApp from "../components/Healthcheck/HealthCheckApp";
import { HealthCheckAppProvider } from "../components/Healthcheck/HealthCheckAppContext";


export default function() {
  const { contentfulAccountingHealthCheckPage } = useStaticQuery(graphql`
    {
      contentfulAccountingHealthCheckPage(slug: {eq: "healthcheck"}) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulAccountingHealthCheckPage.metaTitle,
      description: contentfulAccountingHealthCheckPage.metaDescription,
      image:
        contentfulAccountingHealthCheckPage.image &&
        contentfulAccountingHealthCheckPage.image.localFile.childImageSharp.fluid.src,
    },
  };
  const background = '#2D2D2D';
  const HeadingContainer = styled(Box).attrs(({ background }) => ({
    background,
    pad: { vertical: 'large'},
    direction: 'row',
    justify: 'center'
  }))``;
  
  const InnerContainer = styled(Box)`
    max-width: 1180px;
    width: 100%;
    padding: 0 20px;
  `;
  
  const Title = styled(Heading).attrs({
    level: 2,
    margin: {
      vertical: 'none',
    }
  })``;
  
  const Subtitle = styled(Paragraph)`
    margin: 0
  `;



  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <ResponsiveContext.Consumer>
        {(size) => {
          const isSmall = size !== 'medium' && size !== 'large';
          return (
            <>
              <HeadingContainer background={background}>
                <InnerContainer>
                  <Title>Accounting Health Check</Title>
                  <Subtitle fill>
                  Is accounting helping your business perform?
                  </Subtitle>
                  </InnerContainer>
              </HeadingContainer>
              <Box
                margin='0 auto'
                fill
                width={{
                  max: '1180px'
                }}
                direction="row-responsive"
                justify="between"
                pad={{ horizontal: '20px' }}
                background={{
                  "repeat": "no-repeat",
                  "size": "40%",
                  "position": "top 40px right",
                  "image": "url(//images.ctfassets.net/gzpz2xecclqo/29MQ1LuRfczfsiS0IGadUt/e039532b75a7437f1c7793722e70fee4/clouds.svg)"
                }}
                >
                <Box
                  height={{
                    min: '50vh'
                  }}
                  direction="column"
                  alignContent="start"
                >
                 
                  <HealthCheckAppProvider>
                    <HealthCheckApp />
                  </HealthCheckAppProvider>
                </Box>
              </Box>
              <Box
                margin='0 auto'
                fill
                width={{
                  max: '1180px'
                }}
                pad={{ horizontal: '20px' }}
                direction={isSmall  ? "column-reverse" : "row"}
                justify="between"
              >
                <Box
                  width={{
                    max: '480px'
                  }}
                  direction="row"
                  alignContent="end"
                  wrap="true"
                >
                  <Paragraph
                    size="xsmall"
                    style={{
                      paddingRight: '20px'
                    }}
                    >
                    Solicitation ©2023 PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see www.pwc.com/structure for further details.
                  </Paragraph> 
                </Box>
                <Box>
                  <Image src="//images.ctfassets.net/l07wyut35yzl/5G1Gr0ltaDzv4DfrHAGxD/d96789c2b6e01272584d684354967cbd/io-health-check.svg" />
                </Box>
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
