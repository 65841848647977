import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { LinkUp, Validate } from 'grommet-icons';
import { Anchor, Button, Box, Heading, Paragraph, Text } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";
import { BreakdownScoreImage } from "../ui/BreakdownScoreImage";
import { ScoreImage } from "../ui/ScoreImage";
import { ScoreExplain } from "../ui/ScoreExplain";

const NextButton = styled(Button)`
  background: #EA8C00;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #000000;
  max-width: 280px;
  padding:  8px 16px;
  border-radius: 6px;
`;

const ScoreContainer = styled(Box)`
    margin-top: 20px; 
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #D2D2D2;
    border-radius: 6px;
    padding: 40px;
`;

const ScoreHeading = styled(Heading)`
    padding: 0;
    margin: 0 0 30px 0;
`;

const ScoreText = styled(Text)`
    padding: 0;
    margin-top: 40px;
    margin-right: 80px;
`;

const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
 };

export function ResultBreakdownStep({ isSmall, breakdownCalc, overallCalc }) {
    const {
        state,
        dispatch,
    } = useHealthCheckAppState();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-resultbreakdown-score' });
        console.log(overallCalc);
    }, [])

    return (
        <>
            <Box
                style={{
                    margin: '40px 0 0'
                }}>
                <Heading level="3" style={{margin:0}}>Great! Here are your results.</Heading>
                <Heading level="4">This includes a breakdown of your accounting health&mdash;what your answers said:</Heading>
            </Box>
            <Box 
                direction={isSmall  ? "column-reverse" : "row-responsive"}
                justify="between"
                gap="large"
            >
            <Box>
                <ScoreContainer>
                    <ScoreHeading level="4">Your total health score:</ScoreHeading>
                    <ScoreImage totalScore={overallCalc} />
                    <ScoreExplain totalScore={overallCalc} />
                </ScoreContainer>
                <ScoreContainer>
                    <ScoreHeading level="4">Accuracy score:</ScoreHeading>
                    <BreakdownScoreImage breakdownScore={breakdownCalc.accuracyTotalScore} />
                    <ScoreText><Anchor href="https://bookkeepingconnect.pwc.com/resources/how-accurate-is-your-company-accounting" target="_blank">Read more</Anchor> about how to <b>drive greater accuracy</b> in your books.</ScoreText>
                </ScoreContainer>
                <ScoreContainer>
                    <ScoreHeading level="4">Timeliness score:</ScoreHeading>
                    <BreakdownScoreImage breakdownScore={breakdownCalc.timelinessTotalScore} />
                    <ScoreText><Anchor href="https://bookkeepingconnect.pwc.com/resources/timeliness-dont-let-delayed-bookkeeping-derail-your-company-growth" target="_blank">Read more</Anchor> about how <b>timely books</b> can help you avoid pitfalls.</ScoreText>
                </ScoreContainer>
                <ScoreContainer>
                    <ScoreHeading level="4">Automating payments score:</ScoreHeading>
                    <BreakdownScoreImage breakdownScore={breakdownCalc.automationTotalScore} />
                    <ScoreText><Anchor href="https://bookkeepingconnect.pwc.com/resources/the-high-cost-of-writing-paper-checks/" target="_blank">Read more</Anchor> about how easy it is to switch to more <b>automated payments</b>.</ScoreText>
                </ScoreContainer>
                <ScoreContainer>
                    <ScoreHeading level="4">Relevancy score:</ScoreHeading>
                    <BreakdownScoreImage breakdownScore={breakdownCalc.relevancyTotalScore} />
                    <ScoreText><Anchor href="https://bookkeepingconnect.pwc.com/resources/four-reports-that-can-help-you-take-charge-of-your-company-finances" target="_blank">Read more</Anchor> about the four <b>essential reports</b> you need.</ScoreText>
                </ScoreContainer>
                <ScoreContainer>
                    <ScoreHeading level="4">Value-creation score:</ScoreHeading>
                    <BreakdownScoreImage breakdownScore={breakdownCalc.valueCreationTotalScore} />
                    <ScoreText><Anchor href="https://bookkeepingconnect.pwc.com/resources/unleash-the-power-of-your-accounting-function/" target="_blank">Read more</Anchor> about how your accounting function can <b>drive value</b> for your business.</ScoreText>
                </ScoreContainer>
                <Box>
                    <Paragraph>
                        <Anchor 
                            style={{
                                textDecoration: 'none',
                                fontWeight: 'bold'
                            }}
                            onClick={e => {
                                e.preventDefault();
                                scrollTop();
                            }}><LinkUp color='focus' /> Back to top </Anchor>
                    </Paragraph>
                </Box>
            </Box>
            <Box>
                <Box
                    border={{
                        "color": "#EA8C00",
                        "size": "8px",
                        "style": "solid",
                        "side": "all"
                    }}
                    margin="medium"
                    pad="large"
                    style={{
                        borderRadius: "6px"
                    }}
                >
                    <Validate
                        size="large"
                        style={{
                            marginBottom: '20px'
                        }}
                    />
                    <Heading
                        level="3"
                        margin="0 0 20px 0"
                    >Get your books in order with Bookkeeping Connect.</Heading>
                    <Paragraph>
                        Our <b>bookkeeping, accounting and reporting solution</b> for organizations remove your day-to-day accounting burden. With accurate and timely data, you can see a true picture of your financial health, allowing you to <b>make the right strategic decisions</b>.<br /><br />
                        With <b>Bookkeeping Connect</b>, you'll get access to:
                        <ul
                            style={{
                                marginLeft: '-20px',
                                paddingBottom: '10px'
                            }}>
                            <li>Daily transaction coding and reconciliation
                            </li><li>Books delivered on-time by the 5th of each month
                            </li><li>Essential reports that enable you to make business-driving decisions</li></ul>
                    </Paragraph>
                    <NextButton target="_blank" href="/how-it-works?utm_source=healthcheck_app_breakdownscore">Learn how it works</NextButton>
                </Box>      
            </Box>
            </Box>
        </>
    );
}

export default ResultBreakdownStep;
