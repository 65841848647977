import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Box, Heading, Paragraph, ThemeContext } from 'grommet';
import { Checkmark, FormNextLink} from 'grommet-icons';
import { FormModalContext } from '@pwc-new-ventures/ui';
import { useHealthCheckAppState } from "../HealthCheckAppContext";
import { ScoreImage } from "../ui/ScoreImage";
import { ScoreExplain } from "../ui/ScoreExplain";

const NextButton = styled(Button)`
  background: #EA8C00;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #FFFFFF;
  width: 300px;
  padding:  8px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled(Button)`
  background: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #EA8C00;
  width: 80px;
  padding:  8px 16px;
  border-radius: 6px;
  margin-right: 20px;
`;

const SuccessFormSubmit = ({ handleModal, handleNextStep }) => {

  const handleClose = () => {
    handleModal(null);
    handleNextStep();
  }

  return (
    <>
    <Box align="center" pad="large" flex={false}>
      <ThemeContext.Extend
        value={{
          heading: { level: { '2': { medium: { maxWidth: '600px' } } } },
          paragraph: { medium: { maxWidth: '600px' } },
        }}
      >
        <Checkmark size="large" />
        <Heading level="2" textAlign="center">Thank you.</Heading>
        <Paragraph textAlign="center" margin={{ bottom: 'large' }}>
          Click the button below to reveal your accounting health breakdown.
        </Paragraph>
        <NextButton onClick={handleClose}>Show me the results <FormNextLink size="medium" /></NextButton>
      </ThemeContext.Extend>
    </Box>
    </>
  )
}

export function ResultOverallStep({  goBack, goForward, overallCalc, breakdownCalc }) {
  const {
    state,
    dispatch,
  } = useHealthCheckAppState();

  // console.log(overallCalc);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-resultoverall-score' });
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: false })
  }, [])

  return (
    <>
      <Box
        style={{
          marginTop: '20px',
          width: '100%',
          maxWidth: '520px',
        }}>
        <Heading level="3">Great! Here are your results.</Heading>
        <Paragraph
          style={{
            marginTop: '20px',
            maxWidth: '520px',
            width: '100%'
          }}>Based on your responses, your <b>bookkeeping health</b> is:</Paragraph>
        </Box>
        <Box 
        direction="column" 
        margin={{ top: "medium" }}
        pad={{ bottom: '40px' }}
        style={{
          maxWidth: '520px'
        }}
        >
        <ScoreImage totalScore={overallCalc} />
        <ScoreExplain totalScore={overallCalc} />

        <Heading level="4" style={{margin: '0'}}>Numbers don't tell the whole story.</Heading>
        <Paragraph>In the next section, we will show you a <b>breakdown of this health score</b> and <i>recommend</i> resources that are actionable today.</Paragraph>
        {/* <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '30px',
        }}>
          <BackButton onClick={() => goBack()}>Back</BackButton>
          <FormModalContext.Consumer>
            {({ setShowModal }) => (
              <NextButton onClick={() => { setShowModal({ slug: 'form-accounting-healthcheck', hiddenData: { total_health_score: overallCalc, 
                accuracy_score: breakdownCalc.accuracyTotalScore, 
                timeliness_score: breakdownCalc.timelinessTotalScore,
                 automation_score: breakdownCalc.automationTotalScore, 
                 relevancy_score: breakdownCalc.relevancyTotalScore, 
                 valuecreation_score: breakdownCalc.valueCreationTotalScore,
                  }, customSuccess: () => <SuccessFormSubmit handleModal={setShowModal} handleNextStep={goForward} /> } ) }}>Show me the breakdown <FormNextLink size="medium" /></NextButton>
            )}
            </FormModalContext.Consumer>
        </Box> */}
        <Paragraph
          style={{
            fontSize: '13px',
            lineHeight: '18px',
            marginTop: '60px'
          }}>This estimate is for general demonstration purposes only. For simplicity, this accounting health result makes a series of assumptions and this estimate should not be relied upon for any business decisions.  It is not a substitute for legal, accounting, tax, financial, or other advice or services that would otherwise be provided by a certified professional.</Paragraph>
      </Box>
    </>
  );
}

export default ResultOverallStep;
