import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Form, Box, RadioButtonGroup, Paragraph, Heading } from 'grommet';
import { useHealthCheckAppState } from "../HealthCheckAppContext";

const NextButton = styled(Button)`
  background: #EA8C00;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #FFFFFF;
  width: 300px;
  padding:  8px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackButton = styled(Button)`
  background: #FFFFFF;
  border-width: 2px;
  border-style: solid;
  border-color: #EA8C00;
  color: #EA8C00;
  width: 80px;
  padding:  8px 16px;
  border-radius: 6px;
  margin-right: 20px;
`;


export function ValueCreationStep( isSmall, goBack, goForward, overallCalc, breakdownCalc ) {
  const {
    state: { valueCreationScore },
    dispatch,
  } = useHealthCheckAppState();

  const isValid = valueCreationScore !== '';
  const optionsDesktop =
    [
      { label: 'I feel like I have a good sense of where we stand', value: '5-1' },
      { label: 'I`m not able to rely on the data to make confident decisions', value: '5-2' },
      { label: 'Getting reports takes so long that I can’t wait for the information I need', value: '3' },
      { label: 'All of the above', value: '5-3' },
      { label: 'Our books reliably form the foundation of ALL of our value-driving decisions', value: '1' },
    ];
  const optionsMobile =
    [
      { label: 'I have a good sense of where we stand', value: '5-1' },
      { label: 'I cannot rely on our data for decisions', value: '5-2' },
      { label: 'Getting reports takes too long', value: '3' },
      { label: 'All of the above', value: '5-3' },
      { label: 'Our books drive our big decisions!', value: '1' },
    ];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.dataLayer) window.dataLayer.push({ event: 'healthcheck-step-valuecreation' });
  }, [])

  useEffect(() => {
    dispatch({ type: "SHOULD_ALLOW_NEXT", payload: isValid })
  }, [valueCreationScore])

  return (
    <>
      <Box
        style={{
          marginTop: '20px'
        }}>
        <Heading level="3">And finally...</Heading>
        <Paragraph margin={{
          bottom: '20px',
          top: 'small'
        }}>Which of these best describes the <b>degree to which you can rely</b> on your accounting function to help you <b>make value-driving decisions</b> for your business?</Paragraph>
      </Box>
      
      <Box 
        direction="row" 
        justify="between" 
        align="center"
        margin={{ top: "medium" }}
        pad={{ bottom: '40px' }}
        >
        <Form>
        <RadioButtonGroup
          name="valueCreationScore"
          gap="medium"
          options={(isSmall) ? optionsMobile : optionsDesktop}
          value={valueCreationScore}
          onChange={(event) => {
            dispatch({ type: "VALUECREATION_SCORE_CHANGE", payload: event.target.value });
            console.log(valueCreationScore);
          }}
        />
        </Form>
      </Box>
    </>
  );
}

export default ValueCreationStep;
