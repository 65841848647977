import React, { createContext, useReducer, useContext } from "react";

function formReducer(state, action) {
  switch (action.type) {
    case "ACCURACY_SCORE_CHANGE":
      return { ...state, accuracyScore: action.payload };
    case "TIMELINESS_REPORT_SCORE_CHANGE":
      return { ...state, timelinessReportScore: action.payload };
    case "AUTOMATION_SCORE_CHANGE":
      return { ...state, automationScore: action.payload };
    case "TIMELINESS_BOOKCLOSING_SCORE_CHANGE":
      return { ...state, timelinessBookClosingScore: action.payload };
    case "RELEVANCY_SCORE_CHANGE":
      return { ...state, relevancyScore: action.payload };
    case "VALUECREATION_SCORE_CHANGE":
      return { ...state, valueCreationScore: action.payload };
    case 'SHOULD_ALLOW_NEXT':
      return { ...state, shouldAllowNext: action.payload };
    case 'SHOULD_RENDER_STEP_INFO':
      return { ...state, shouldRenderStepInfo: action.payload };
    case "SUBMIT":
      return { ...state, isSubmitLoading: true };
    case "SUBMISSION_RECEIVED":
      return { ...state, isSubmitLoading: false, isSubmissionReceived: true };
    default:
      throw new Error();
  }
}

const HealthCheckAppContext = createContext();

const initialState = {
  accuracyScore: '',
  timelinessReportScore: '',
  timelinessBookClosingScore: '',
  relevancyScore: '',
  automationScore: '',
  valueCreationScore: '',
  accuracyTotalScore: '',
  timelinessTotalScore: '',
  relevancyTotalScore: '',
  automationTotalScore: '',
  valueCreationTotalScore: '',
};

export const HealthCheckAppProvider = function({ children }) {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <HealthCheckAppContext.Provider value={{ state, dispatch }}>
      {children}
    </HealthCheckAppContext.Provider>
  );
};

export function useHealthCheckAppState() {
  const context = useContext(HealthCheckAppContext);

  if (context === undefined) {
    throw new Error(
      "useHealthCheckAppState must be used within a HealthCheckAppProvider"
    );
  }

  return context;
}
